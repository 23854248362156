
import React from 'react'
import { graphql } from 'gatsby'
export default ({ data }) => {
    console.log(data)
    return (
        <div>
             {data.allGhostPost.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>{node.slug}</td>
                <td>{node.title}</td>
                <td>{node.published_at}</td>
                <td>{node.id}</td>
              </tr>
            ))}
        </div>
    )
}

export const allGhostPost = graphql`
    query allGhostPost{
        allGhostPost(sort: { order: DESC, fields: [published_at] }) {
            edges {
                node {
                    id
                    slug
                    title
                    html
                    published_at
                    tags {
                        id
                        slug
                    }      
                }
            }
        }
    }
`
